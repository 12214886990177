import { createSlice } from '@reduxjs/toolkit'
import { IStore } from './types'

const initialState: IStore['multipleScreensTestData'] = {
  trackSessionId: 0,
  showNewScreen: false,
  todaySlots: null,
  todaySelected: null,
}

export const multipleScreensTestDataSlice = createSlice({
  name: 'multipleScreensTestData',
  initialState,
  reducers: {
    setMultipleScreensTestData: (state, action) => {
      return {
        ...state,
        ...action.payload,
      }
    },
    setMultipleTestDataTodaySlots: (state, action) => {
      return {
        ...state,
        todaySlots: action.payload,
      }
    },
    setMultipleTestDataTodaySelected: (state, action) => {
      return {
        ...state,
        todaySelected: action.payload,
      }
    },
  },
})

export const { setMultipleScreensTestData, setMultipleTestDataTodaySlots, setMultipleTestDataTodaySelected } =
  multipleScreensTestDataSlice.actions

export default multipleScreensTestDataSlice.reducer
