import { createSlice } from '@reduxjs/toolkit'
import { IStore } from './types'

const initialState: IStore['currentScreen'] = 'item_type'

export const screenSlice = createSlice({
  name: 'screen',
  initialState,
  reducers: {
    updateScreen: (state, action) => action.payload,
  },
})

export const { updateScreen } = screenSlice.actions

export default screenSlice.reducer
