import { createSlice } from '@reduxjs/toolkit'
import { IStore } from './types'

const initialState: IStore['appointmentInfo'] = {
  fromOfficePage: false,
  modalWasShown: false,
  notification: false,
  office: null,
  time: '',
  day: '',
  appointmentType: 'meeting',
  prevAppointmentData: null,
}

export const appointmentInfoSlice = createSlice({
  name: 'appointmentInfo',
  initialState,
  reducers: {
    setAppointmentOffice: (state, action) => {
      state.office = action.payload
    },
    setAppointmentTime: (state, action) => {
      state.time = action.payload
    },
    setAppointmentDay: (state, action) => {
      state.day = action.payload
    },
    setFromOfficePage: (state) => {
      state.fromOfficePage = true
    },
    setNotFromOfficePage: (state) => {
      state.fromOfficePage = false
    },
    setModalWasShown: (state) => {
      state.modalWasShown = true
    },
    setModalWasHide: (state) => {
      state.modalWasShown = false
    },
    setNotificationOn: (state) => {
      state.notification = true
    },
    setNotificationOff: (state) => {
      state.notification = false
    },
    setAppointmentType: (state, action) => {
      state.appointmentType = action.payload
    },
    setLocalAppointment: (state, action) => action.payload,
    clearAppointment: () => initialState,
    setAppointmentInfoFields: (state, action) => {
      return {
        ...state,
        ...action.payload,
      }
    },
    setPrevAppointmentData: (state, action) => {
      return {
        ...state,
        prevAppointmentData: {
          ...action.payload,
        },
      }
    },
    clearAppointmentPrevData: (state) => {
      return {
        ...state,
        prevAppointmentData: null,
      }
    },
  },
})

export const {
  setAppointmentOffice,
  setAppointmentTime,
  setLocalAppointment,
  setAppointmentDay,
  clearAppointment,
  setFromOfficePage,
  setNotFromOfficePage,
  setModalWasShown,
  setModalWasHide,
  setNotificationOn,
  setNotificationOff,
  setAppointmentType,
  setAppointmentInfoFields,
  setPrevAppointmentData,
  clearAppointmentPrevData,
} = appointmentInfoSlice.actions

export default appointmentInfoSlice.reducer
